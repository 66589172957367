import {useCallback, useState} from 'react';
import axios from 'axios';
import {Container} from './styled';

function Dashboard({ history }) {
  const token = localStorage.getItem("token");
  const [enviando, setEnviando] = useState(false);

  const handleDownload = useCallback(async () => {
    const response = await axios.get('https://gtx.casadebraganca.com/historico-vendas/xlsx', {
      headers: {Authorization: `Bearer ${token}`}
    });

    const blob = new Blob([response.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });

    const url = window.URL.createObjectURL(blob);
    window.open(url);
  }, [token]);

  const handleUpload = useCallback(async (e) => {
    if(!e.target.files) return;

    const data = new FormData();
    data.append('file', e.target.files[0]);
    
    setEnviando(true);
    await axios.post('https://gtx.casadebraganca.com/historico-vendas', data, {
      headers: {Authorization: `Bearer ${token}`}
    });

    setEnviando(false);

  }, [token]);
  
  const doLogout = useCallback(() => {
    localStorage.removeItem('token');
    history.push('/');
  }, [history]);

  return <Container>
    <button onClick={handleDownload}>Download</button>
    <form action="https://gtx.casadebraganca.com/historico-vendas" method="post" encType="multipart/form-data">
      <label>{enviando ? "Enviando o arquivo" : "Upload"}
        <input name="file" type="file" style={{display: 'none'}} onChange={handleUpload} />
      </label>
      {/* <button type="submit">Enviar</button> */}
    </form>
    <button onClick={doLogout}>Sair</button>
  </Container>
}

export default Dashboard;