import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 960px;
  margin: 8rem auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 16px;

  button, label {
    display: block;
    width: 100%;
    background-color: transparent;
    border-radius: 8px;
    border: 2px solid #29c28e;
    padding: 24px;
    font-size: 16px;
    color: #29c28e;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    text-align: center;
  }
`;
