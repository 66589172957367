import { useHistory, Route } from 'react-router-dom';

function DashboardRoute ({component, path}) {
  const history = useHistory();
  const token = localStorage.getItem('token');

  if(!token) history.push('/');

  return <Route path={path} component={component} />;
}

export default DashboardRoute;