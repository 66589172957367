import { useCallback, useState, useEffect } from 'react';
import axios from 'axios';

import wave from '../assets/wave.png';
import bg from '../assets/bg.svg';
import logo from '../assets/gtx.png';

function Login ({history}) {

	const [usernameFocused, setUsernameFocused] = useState(false);
	const [passwordFocused, setPasswordFocused] = useState(false);

	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');

	const DoLogin = useCallback(async (e) => {
		e.preventDefault();

		try {
			const response = await axios.post('https://gtx.casadebraganca.com/session', {
				username,
				password
			});

			const { token } = response.data;
			localStorage.setItem("token", token);

			history.push('/dashboard');
		} catch (e) {
			alert(JSON.parse(e.request.response).error);
		}
	}, [password, username, history]);

	useEffect(() => {
		const token = localStorage.getItem('token');

		if(token) history.push('/dashboard');
	}, [history]);

  return <><img className="wave" src={wave} alt="Wave" />
	<div className="container">
		<div className="img">
			<img src={bg} alt="Imagem surrealista de um celular com algumas folhas a sua volta e um homem sentado nele" />
		</div>
		<div className="login-content">
			<form onSubmit={DoLogin}>
				<img src={logo} alt="Logo da GTX" />
				<h2 className="title">Bem vindo</h2>
					<div className={`${usernameFocused ? "focus" : ""} input-div one`}>
							<div className="i">
								<i className="fas fa-user"></i>
							</div>
							<div className="div">
								<h5>Username</h5>
								<input type="text" className="input" value={username||""} onChange={e => setUsername(e.target.value)} onFocus={() => setUsernameFocused(true)} onBlur={() => !username && setUsernameFocused(false)} />
							</div>
					</div>
					<div className={`${passwordFocused ? "focus" : ""} input-div pass`}>
						<div className="i"> 
							<i className="fas fa-lock"></i>
						</div>
						<div className="div">
							<h5>Password</h5>
							<input type="password" className="input" value={password||""} onChange={e => setPassword(e.target.value)} onFocus={() => setPasswordFocused(true)} onBlur={() => !password && setPasswordFocused(false)} />
						</div>
					</div>
					{/* <a href="#">Esqueceu senha?</a> */}
					<input type="submit" className="btn" value="Login" />
				</form>
		</div>
	</div></>;
}

export default Login;